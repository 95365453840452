// Check if the device is a touchscreen
function isTouchDevice() {
    return navigator.maxTouchPoints > 0;
}

if (isTouchDevice() && window.innerWidth <= 1100) {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('in-view');
            }
        });
    });

    // Select all cards to observe
    const cards = document.querySelectorAll('.card');
    cards.forEach(card => observer.observe(card));
}


